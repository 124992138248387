import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    blackout: "#37352f",
    blackoutHover: "#4d4a41",
  },
};

export default extendTheme(theme);
